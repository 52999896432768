<template>
<b-modal
      
      :id="modalId"
      ref="modal"
      :title="title"
      @show="resetModal"
      @hidden="resetModal"
      @ok="ok"
      
    >
      <form ref="form" @submit.stop.prevent="ok">
        <b-form-group
          :label="label"
          label-for="name-input"
        >
          <b-form-input
            id="name-input"
            v-model="text"
            autofocus
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
</template>

<script>
export default {
  name: 'TextInputModal',
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: ''
    },
    okButtonText: {
      type: String,
      default: 'OK'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    }
  },
  data() {
    return {
      text: '',
    }
  },
  methods: {
    resetModal() {
      this.text = ''
    },
    ok(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$emit('ok', this.text);
      this.$nextTick(() => {
          this.$bvModal.hide(this.modalId)
        })
    }
  }
}
</script>

<style>

</style>