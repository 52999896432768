<template>
  <b-container>
    <h1>{{$t('roles.roles')}}</h1>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-overlay show spinner-variant="primary" no-wrap />
      </template>
      <b-card class="shadow mt-2">
        <div class="d-flex justify-content-end mb-2">
          <b-button
            variant="outline-secondary"
            pill
            v-b-modal.inputModal
            ><i class="fas fa-plus mr-2"></i>{{$t('roles.newRole')}}</b-button
          >
        </div>
        <b-list-group>
          <router-link
            v-for="role in roles"
            :key="role.name"
            :to="`/roles/${role.name}`"
            ><b-list-group-item button>
              <span>{{ role.name }}</span>
            </b-list-group-item></router-link
          >
        </b-list-group>
      </b-card>
    </b-skeleton-wrapper>

    <text-input-modal modalId="inputModal" :title="$t('roles.newRole')" :label="$t('roles.EnterRoleName')" @ok="addRole" />


  </b-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import TextInputModal from "@/components/TextInputModal.vue";

export default {
  name: "Roles",
  components: { TextInputModal },
  data() {
    return {
      loading: false,
      roles: [],
    };
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    addRole(eventData) {
      if(!eventData) return;

      axios
        .post(`/my-devices/api/roles`,JSON.stringify({
          name: eventData
        }))
        .then(() => {
          this.fireAlert([this.$t('Success'), this.$t('roles.CreatedRoleSuccess'), 'success']);
          this.$router.push({name: 'Role', params: {name: eventData}});
        })
        .catch((err) => {
          console.err(err);
          this.fireAlert([this.$t('Error'), this.$t('roles.CreatedRoleError'), 'danger']);

        })
    },
  },
  mounted() {
    this.loading = true;
    axios
      .get("https://remote.eospowersolutions.com/my-devices/api/roles")
      .then((res) => {
        this.roles = res.data;
      })
      .catch((err) => {
        console.error(err);
        this.fireAlert([
          this.$t("Error"),
          this.$t("roles.errorLoading"),
          "danger",
        ]);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>
</style>